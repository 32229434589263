"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
var material_1 = require("@mui/material");
var BaseNav_1 = require("./BaseNav");
var NavItem = function (_a) {
    var item = _a.item, handleClick = _a.handleClick, style = _a.style;
    var pathname = (0, react_router_dom_1.useLocation)().pathname;
    var isMatchPathname = (0, react_1.useMemo)(function () {
        var fullPath = "";
        var pathnames = pathname.split("/").filter(function (x) { return x; });
        for (var _i = 0, pathnames_1 = pathnames; _i < pathnames_1.length; _i++) {
            var pathname_1 = pathnames_1[_i];
            fullPath = fullPath + "/" + pathname_1;
        }
        if (fullPath.length === 0 && item.href === "/") {
            return true;
        }
        return fullPath === item.href;
    }, [pathname, item]);
    return (react_1.default.createElement(material_1.ListItem, { sx: __assign(__assign({}, style), { py: 0.5 }), disableGutters: true },
        react_1.default.createElement(material_1.Link, { href: item.href, underline: "none", sx: { width: "100%" }, rel: "noreferrer" },
            react_1.default.createElement(BaseNav_1.NavItemWrapper, { onClick: handleClick, style: {
                    color: isMatchPathname ? "#ea2227" : "#9CA3AF",
                } },
                item.icon,
                react_1.default.createElement(BaseNav_1.NavItemTitle, null, item.name)))));
};
exports.default = NavItem;
