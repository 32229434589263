"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthButton = exports.AuthHeader = exports.AuthCard = exports.AuthContainer = void 0;
var material_1 = require("@mui/material");
var Container = (0, material_1.styled)(material_1.Box)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            margin: "auto",
            width: "80%"
        },
        _b[theme.breakpoints.up("md")] = {
            width: "24rem",
        },
        _b);
});
exports.AuthContainer = Container;
var Card = (0, material_1.styled)(material_1.Box)(function () { return ({
    backgroundColor: "#ffffff",
    padding: 24,
    borderRadius: 6,
    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px",
}); });
exports.AuthCard = Card;
var Header = (0, material_1.styled)(material_1.Typography)(function () { return ({
    color: "#374151",
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "1.75rem",
    marginBottom: 16,
    textAlign: "center",
}); });
exports.AuthHeader = Header;
var Button = (0, material_1.styled)(material_1.Button)({
    width: "100%",
    padding: 8,
    borderRadius: 6,
    color: "#ffffff",
    fontWeight: 600,
    textTransform: "none",
    backgroundColor: "rgba(190, 24, 93, 1)",
    "&:hover": {
        backgroundColor: "rgba(219, 39, 119, 1)",
    },
});
exports.AuthButton = Button;
