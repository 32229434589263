"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mappedToSOEData = void 0;
var mappedToSOEData = function (item, title) {
    var data = {
        Description: item.name,
        Category: title,
    };
    for (var i = 0; i < item.column_values.length; i++) {
        var value = item.column_values[i].text;
        switch (i) {
            case 0:
                data.SOE = value;
                break;
            case 1:
                data.PIC = value;
                break;
            case 2:
                data.Startup = value;
                break;
            case 3:
                data.Phase = value;
                break;
            case 4:
                data.Priority = value;
                break;
        }
    }
    return data;
};
exports.mappedToSOEData = mappedToSOEData;
