"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Theme = void 0;
var material_1 = require("@mui/material");
exports.Theme = (0, material_1.createTheme)({
    components: {
        MuiButtonBase: {
            defaultProps: {
                style: {
                    textTransform: 'none'
                }
            }
        }
    },
    palette: {
        primary: {
            light: '#ee4e52',
            main: '#ea2227',
            dark: '#a3171b',
            contrastText: '#fff',
        }
    }
});
