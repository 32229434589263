"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageSubtitle = exports.PageTitle = exports.HeadingTitle = void 0;
var material_1 = require("@mui/material");
var system_1 = require("@mui/system");
var HeadingTitle = (0, system_1.styled)(material_1.Typography)("\n  font-size: 24px;\n  font-weight: 500;\n  padding-bottom: 8px;\n");
exports.HeadingTitle = HeadingTitle;
var PageTitle = (0, system_1.styled)(material_1.Typography)("\n  font-size: 1.125rem;\n  font-weight: 500;\n  fontWeight: 500;\n  color: #111827;\n");
exports.PageTitle = PageTitle;
var PageSubtitle = (0, system_1.styled)(material_1.Typography)("\n  font-size: 16px;\n  font-weight: 500;\n  padding-bottom: 8px;\n");
exports.PageSubtitle = PageSubtitle;
