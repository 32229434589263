require("./bootstrap");

import React from "react";
import { render } from "react-dom";
import { createInertiaApp } from "@inertiajs/inertia-react";
import { BrowserRouter as Router } from "react-router-dom";
import "webdatarocks/webdatarocks.css";
// import NProgress from "nprogress";
// import { Inertia } from "@inertiajs/inertia";
import { InertiaProgress } from "@inertiajs/progress";

createInertiaApp({
    resolve: (name) => require(`./Pages/${name}`),
    setup({ el, App, props }) {
        render(
            <Router>
                <App {...props} />
            </Router>,
            el
        );
    },
});

InertiaProgress.init(
    InertiaProgress.init({
        // The delay after which the progress bar will
        // appear during navigation, in milliseconds.
        delay: 1,

        // The color of the progress bar.
        color: "#29d",

        // Whether to include the default NProgress styles.
        includeCSS: true,

        // Whether the NProgress spinner will be shown.
        showSpinner: true,
    })
);

// Inertia.on("start", (event) => {
//     console.log(`Starting a visit to ${event.detail.visit.url}`);
// });

// let timeout = null;

// Inertia.on("start", () => {
//     timeout = setTimeout(() => NProgress.start(), 0);
// });

// Inertia.on("progress", (event) => {
//     if (NProgress.isStarted() && event.detail.progress.percentage) {
//         NProgress.set((event.detail.progress.percentage / 100) * 0.9);
//     }
// });

// Inertia.on("finish", (event) => {
//     clearTimeout(timeout);
//     if (!NProgress.isStarted()) {
//         return;
//     } else if (event.detail.visit.completed) {
//         NProgress.done();
//     } else if (event.detail.visit.interrupted) {
//         NProgress.set(0);
//     } else if (event.detail.visit.cancelled) {
//         NProgress.done();
//         NProgress.remove();
//     }
// });
