"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NavItemTitle = exports.NavItemWrapper = void 0;
var material_1 = require("@mui/material");
exports.NavItemWrapper = (0, material_1.styled)(material_1.Button)({
    color: '#9CA3AF',
    fontWeight: 400,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    textTransform: 'none',
    width: '100%',
    '&:hover': {
        backgroundColor: '#F3F4F6',
    },
});
exports.NavItemTitle = (0, material_1.styled)(material_1.Typography)({
    marginLeft: 10,
    fontSize: 14,
    lineHeight: '1.25rem'
});
