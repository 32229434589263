"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SOESlice = void 0;
exports.SOESlice = {
    reportFilters: [{ uniqueName: "Category" }],
    rows: [
        { uniqueName: "SOE" },
        { uniqueName: "Startup" },
        { uniqueName: "Measures" },
    ],
    columns: [{ uniqueName: "Phase" }],
    measures: [{ uniqueName: "Startup" }],
};
