"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mappedToTelkomData = void 0;
var mappedToTelkomData = function (item, title) {
    var data = {
        Description: item.name,
        Category: title,
    };
    for (var i = 0; i < item.column_values.length; i++) {
        var value = item.column_values[i].text;
        switch (i) {
            case 0:
                data.CFUFU = value; //error
                break;
            case 1:
                data.Subtelkom = value;
                break;
            case 2:
                data.Startup = value;
                break;
            case 3:
                data.PIC = value;
                break;
            case 4:
                data.Grouping = value;
                break;
            case 5:
                data.Phase = value;
                break;
            case 6:
                data.Priority = value;
                break;
        }
    }
    return data;
};
exports.mappedToTelkomData = mappedToTelkomData;
